import { BaseResponse } from '@api/model/base-response';
import { GetConfig } from '@api/model/get-config';
import endpoints from '@constants/endpoints';

import CelebrityInfoResponse from './_static/celebrity_info.json';
import HomePageResponse from './_static/home_page.json';
import LoginResponse from './_static/login.json';
import RegisterResponse from './_static/register.json';
import ResetPasswordResponse from './_static/reset_password.json';

class API {
  static post = async <T, U>(endpoint: string, data: T): Promise<BaseResponse<U>> => {
    let result;

    if (endpoint == endpoints.LOGIN) {
      result = { data: LoginResponse };
    }

    if (endpoint == endpoints.LOGOUT) {
      result = { data: {} };
    }

    if (endpoint == endpoints.FACEBOOK_LOGIN) {
      result = { data: LoginResponse };
    }

    if (endpoint == endpoints.FORGOT_PASSWORD) {
      result = { data: {} };
    }

    if (endpoint == endpoints.RESET_PASSWORD) {
      result = { data: ResetPasswordResponse };
    }

    if (endpoint == endpoints.REGISTER) {
      result = { data: RegisterResponse };
    }

    if (endpoint == endpoints.EMAIL_OPT_IN) {
      result = { data: {} };
    }

    if (endpoint.startsWith('creators') && endpoint.endsWith('notify-me')) {
      const email = (data as any).email;

      result = { data: { email: email } };
    }

    if (!result) return { success: false, code: 'unknown-error' } as BaseResponse<U>;
    if (result.data.code) return { success: false, code: result.data.code } as BaseResponse<U>;
    else if (result.data.errors) return { success: false, errors: result.data.errors } as BaseResponse<U>;
    return { success: true, data: result.data } as BaseResponse<U>;
  };

  static get = async <T>(endpoint: string, locale?: string): Promise<BaseResponse<T>> => {
    const config: GetConfig = { headers: {} };
    if (locale) {
      config.headers['X-Language'] = locale;
    }

    const locale_str = locale == 'ar' ? 'ar' : 'en';
    let result;

    if (endpoint == endpoints.HOME_PAGE) {
      result = { data: HomePageResponse[locale_str] };
    }

    if (endpoint == endpoints.CATEGORIES) {
      result = { data: {} };
    }

    if (endpoint.startsWith(endpoints.CELEBRITY_INFO(''))) {
      const slug = endpoint.split('/')[2] as keyof typeof CelebrityInfoResponse;

      result = { data: CelebrityInfoResponse[slug][locale_str] };
    }

    if (!result) return { success: false, code: 'unknown-error' } as BaseResponse<T>;
    return { success: true, data: result.data } as BaseResponse<T>;
  };
}

export default API;
